import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({

  main:{
    width: '100%',
  },
  container: {
    // position: "relative",
    padding: "30px",
    width: "100%",
    [theme.breakpoints.up("md")]: {
    //   marginTop: "212px",
    marginTop: "190px",
    padding:'30px'

    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "10px",
      padding:'20px'
    //   marginLeft: "20px",

    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "70px",
    },
  },
  title: {
    color: "#FF2625",
  },
  link: {
    textDecoration: "none",
  },
  button: {
    backgroundColor: "#ff2625",
    padding: "10px",
  },
  banner: {
    width: "580px",
    height: "780px",
    marginLeft:'130px',
    [theme.breakpoints.down("md")]: {
        
       alignItems:'center'
        
      },
    [theme.breakpoints.down("sm")]: {
        // width:"400px",
        // height:"600px",
        // marginLeft:'0',
        display:'none'
      },
  },

  exercise: {
    position: "absolute",
    top: "580px",
    zIndex: -1,
    opacity: 0.1,

    color: "#FF2625",
    [theme.breakpoints.down("xl")]: {
       display: "none",
      },
  },
}));
